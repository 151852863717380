import React from 'react'
import 'twin.macro';
export default function ServicesSide() {
    return (
<div tw="bg-white w-full">
  <div tw="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
    <div>
      <h2 tw="text-base font-semibold text-secondary-900 uppercase tracking-wide">Everything you need</h2>
      <p tw="mt-2 text-3xl font-extrabold text-gray-900">OUR SERVICES</p>
      <p tw="mt-4 text-lg text-gray-500">When we say full service, we mean it.  At Marketing DNA we can help you from the ground up or fill in the gaps to keep you growing, strong.<br /><br />

Our personalized services and programs are unique to you. No one size fits all here. Together we analyze your goals and budget to come up with a tangible plan you can get excited about. No contracts, no hidden fees, no extra fluff.
</p>
    </div>
    <div tw="mt-12 lg:mt-0 lg:col-span-2">
      <dl tw="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
        <div tw="flex">
          <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <div tw="ml-3">
            <dt tw="text-lg leading-6 font-medium text-gray-900">
              Web Design
            </dt>
            <dd tw="mt-2 text-base text-gray-500">
            100% Responsive designs. Template or custom designs available.
            </dd>
          </div>
        </div>

        <div tw="flex">
          <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <div tw="ml-3">
            <dt tw="text-lg leading-6 font-medium text-gray-900">
              SEO
            </dt>
            <dd tw="mt-2 text-base text-gray-500">
            On/Off page SEO, organic rankings, & reputation management.
            </dd>
          </div>
        </div>

        <div tw="flex">
          <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <div tw="ml-3">
            <dt tw="text-lg leading-6 font-medium text-gray-900">
              Par Per Click
            </dt>
            <dd tw="mt-2 text-base text-gray-500">
            Set up, manage, and monitor PPC and retargeting campaigns.
            </dd>
          </div>
        </div>

        <div tw="flex">
          <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <div tw="ml-3">
            <dt tw="text-lg leading-6 font-medium text-gray-900">
              Pagespeed
            </dt>
            <dd tw="mt-2 text-base text-gray-500">
            A faster website equals more conversions and isn’t that what we’re all looking for?
            </dd>
          </div>
        </div>

        <div tw="flex">
          <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <div tw="ml-3">
            <dt tw="text-lg leading-6 font-medium text-gray-900">
            Audits & Analytics
            </dt>
            <dd tw="mt-2 text-base text-gray-500">
            Heat maps and reports on how you can improve your standings.
            </dd>
          </div>
        </div>

        <div tw="flex">
          <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <div tw="ml-3">
            <dt tw="text-lg leading-6 font-medium text-gray-900">
              Social Media
            </dt>
            <dd tw="mt-2 text-base text-gray-500">
            Posting, target followers, and run local ads so don’t have to.
            </dd>
          </div>
        </div>

        <div tw="flex">
          <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <div tw="ml-3">
            <dt tw="text-lg leading-6 font-medium text-gray-900">
              Email Marketing
            </dt>
            <dd tw="mt-2 text-base text-gray-500">
            Campaigns, tracking and reporting to understand your customers.
            </dd>
          </div>
        </div>

        <div tw="flex">
          <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <div tw="ml-3">
            <dt tw="text-lg leading-6 font-medium text-gray-900">
              Shopping Cart
            </dt>
            <dd tw="mt-2 text-base text-gray-500">
            Customize to slide in, pop open, entice buyers, and super easy to checkout, fast.
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</div>

    )
}
